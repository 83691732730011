$secondaryColor: #495057;
.f-dialog-connect-wallet {
    .f-list-connect-wallet {
        .f-list-item {
            justify-content: space-between;
            align-items: center;
            width: 100%;
            min-height: 70px;
            padding: 0.75rem;
            border: 1px solid white;
            margin-bottom: 1rem;
            img {
                max-width: 40px;
            }
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.text-decoration-none {
    text-decoration: none;
}

.primary-color {
    color: #cba461;
}

.grey-primary {
    color: #495057;
}

.justify-content-space-between {
    display: flex;
    justify-content: space-between;
}

.justify-content-end {
    display: flex;
    justify-content: end;
}

.custom-label {
    background: red;
    padding: 2px 3px;
    border-radius: 2.5px;
    color: black;
    font-size: 18px;
}

.custom-icon {
    display: flex;
    align-items: center;
    margin-left: 2px;
    cursor: pointer;
    background-color: #dab46e;
    padding: 3px 8px;
    border-radius: 2.5px;
}

.grey-secondary {
    color: #919eab;
}

h1 {
    color: #cba461;
}

.f-table table thead tr td {
    color: #cba461;
}

.ftable,
thead {
    background: #161c24 !important;
}

.f-input-group,
.f-input-wrap,
.f-input-control {
    border-radius: 8px !important;
    background: transparent !important;
    color: white !important;
    border-color: #495057 !important;
}

.f-select__single-value {
    color: white !important;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 100px #181c25 inset !important;
    -webkit-text-fill-color: #fff;
}

.custom-input .f-input-group .f-input-wrap:focus-within {
    border-radius: 8px !important;
}

.f-btn-primary {
    border-radius: 8px !important;
    font-weight: 700 !important;
}

.f-input-checkbox input[type="checkbox"]:not(:checked)+label::before {
    background: transparent !important;
}

.f-input-select {
    .f-select {
        .f-select__menu {
            background: #161c24 !important;
            .f-select__menu-list {
                .f-select__option {
                    background: #212b36 !important;
                }
            }
        }
    }
}

.f-select__control {
    input {
        color: white !important;
    }
}

aside.open .f-sider-item.has-child.f-sider-item {
    border: none !important;
}

aside.sider-expanded .f-sider-item.has-child.f-sider-item {
    border: none !important;
}

aside.sider-expanded .f-sider-item {
    border: none !important;
}

aside.collapsed .has-child.f-sider-item.submenu-open .f-sider-submenu-item {
    background: #212b36 !important;
}

.f-dialog .f-dialog-content {
    background: #212b36 !important;
}

// #212b36 // sec
// #161c24 // pri
.f-table {
    .controlRow__root {
        .ButtonGroup__root {
            button:disabled {
                background: #161c24 !important;
                color: white !important;
            }
            button:not([disabled]):nth-last-child(-n + 7) {
                background: #212b36 !important;
                color: white !important;
            }
            .btn:hover,
            .btn:active {
                background-color: #cba461 !important;
            }
        }
    }
}
.f-link {
    color: #cba461;
    text-decoration: none;
}

.f-dialog.bg-white .f-dialog-content {
    background: white !important;
}